import React, { useEffect, useState } from "react";
import { Button, Modal, Accordion, Row, Col, Figure, FigureImage, Image, Dropdown, ButtonGroup, useAccordionButton, Card } from "react-bootstrap";
import '../css/sat-modal.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useUser } from "../contexts/user";
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import { MsgTypes } from "../utils/Websockets";
import { getScheme, getSection } from '../utils/Utils';
import { sortBy } from "lodash";
import { LuChevronUp, LuPlus, LuTrash2 } from "react-icons/lu";
import { Link } from "react-router-dom";
import ModalNewSchema from "./ModalNewSchema";
import ModalSchemaEntry from "./ModalSchemaEntry";

//import {ReactComponent as Explosive} from "../assets/images/WEZIcons/explosive.svg"

let timeoutId;
const sections = [
    "SUMMARIES_AND_MISC",
    "LAUNCH_DETAILS",
    "MISSION_DETAILS",
    "CHARACTERISTICS",
    "FREQUENCIES_AND_COMMS",
    "SATELLITE_STATUS",
    "WEZ_INFO",
]
const ignoreSchemas = [
    "last_updated_at",
    "last_updated_by",
    "nominated_at",
    "nominated_by",
]
const ignoreSource = [
    "turion",
]

function AccordionToggle({ children, eventKey, style, className }) {

    const customOnClick = useAccordionButton(eventKey);

    return (
        <button
            className={className}
            style={style}
            onClick={customOnClick}
        >
            {children}
        </button>
    );
}

function ModalEditData({
    show,
    setShow,
    showFooter,
    selectedSat,
}) {
    const { ws, newMsg } = useUser();
    const [showAddSchemaModal, setShowAddSchemaModal] = useState(false);
    const [showNewSchemaModal, setShowNewSchemaModal] = useState(false);
    const [selectedSchemeData, setSelectedSchemeData] = useState(null);
    const [selectedScheme, setSelectedScheme] = useState("");
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [schema, setSchema] = useState([]);
    const [delSchema, setDelSchema] = useState([]);
    const [deleteId, setDeleteId] = useState("");
    const [expandedItem, setExpandedItem] = useState("0");

    const sortSchema = (unsortSchema) => {
        return sortBy(unsortSchema, Date.parse(unsortSchema.UpdatedAt)).reverse();
    }

    const handleClose = () => setShow(false);

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this schema?\n\nNote: refresh may needed for changes to appear on dosier page.")) {
            setDeleteId(id);
            ws.DeleteSatellite({ Id: id });

            // Immediately update the local state
            setDelSchema(del => { return [...del, id] });

            setUpdateSuccess(
                <span style={{ color: "#ffc107" }}>
                    <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleting...
                </span>
            );

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    };

    const handleAddSchema = (newSchema) => {
        setSelectedSchemeData(newSchema);
    };

    const handleAddSchemaClick = (schemeData = null, scheme = "") => {
        setSelectedSchemeData(schemeData);
        setSelectedScheme(scheme);
        setShowAddSchemaModal(true);
    };

    useEffect(() => {
        if (!newMsg) return;
        if (newMsg.id === MsgTypes.DeleteSatellite) {
            if (newMsg.data.affectedRows === 1) {
                setUpdateSuccess(
                    <span style={{ color: "#ffc107" }}>
                        <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleted
                    </span>
                );
                setSchema(schem => schem.filter(schema => schema.Id !== newMsg.data.deletedId));
            } else {
                setUpdateSuccess(
                    <span style={{ color: "red" }}>
                        <CiCircleRemove style={{ fontSize: "1.5rem" }} /> Error deleting, please try again.
                    </span>
                );
            }
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    }, [newMsg]);

    useEffect(() => {
        if (!selectedSat?.Data) return;
        const filteredData = selectedSat.Data.filter((x) => {
            return !ignoreSchemas.includes(x.Scheme)
                && !delSchema.includes(x.Id);
        });
        setSchema(filteredData);
    }, [selectedSat, delSchema, selectedSchemeData]);

    const handlePersistAccordion = (e) => {
        setExpandedItem(e);
    }

    const getSchemeFromSection = (sectionEntries) => {
        const schema = sectionEntries.map((sec) => sec.Scheme);
        return [...new Set(schema)];
    }

    const wezInfoText = (data) => {
        let parseVal;
        try {
            parseVal = JSON.parse(data.Value); // Attempt to parse the JSON string
        } catch (error) {
            console.error("Failed to parse detail.Value as JSON:", error);
            parseVal={}
        }
        const keys = Object.keys(parseVal);
        return(
            keys.map((key) => `${key ? key + " => " + parseVal[key] + "\n" : ""}`)
        )
    }

    const renderAccordionItems = (sectionData, valueDisplayFilter = (element) => { return element.Value }) => {
        return (sectionData[0] && sectionData[0].Value !== "N/A" &&
            (<Card key={sectionData[0].Id}>
                <Card.Header className="border-0 py-4">
                    <Row
                        className="align-items-center align-text-center"
                        style={{
                            fontWeight: 700
                        }}
                    >
                        <Col style={{ minWidth: "50%" }}>
                            <Row className="align-items-center align-text-center">
                                <Col style={{ minWidth: "150px" }} className="py-2">{sectionData[0].Scheme.replaceAll("_", "\u200b_")}</Col>
                                <Col style={{ minWidth: "80%" }}>
                                    <div style={{ maxWidth: "100%" }} className="py-2">{sectionData[0].Value
                                        ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowY: "auto", maxHeight: "110px", whiteSpace:"preserve-breaks" }}>{valueDisplayFilter(sectionData[0])}</div>
                                        : ""}
                                    </div>
                                    <div style={{ display: "inline-flex", textWrap: "nowrap", maxWidth: "100%" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                        <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>{sectionData[0].Source ? <Link to={sectionData[0].Source}>{sectionData[0].Source}</Link> : <span style={{color:"red"}}>Missing Source</span>}</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ minWidth: "175px" }}>
                            <Row className="align-items-center align-text-center">
                                <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                        <Row>
                                                <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{sectionData[0].UpdatedAt}</Col></Row></Col>
                                                <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{sectionData[0].VerifiedBy}</Col></Row></Col>
                                        </Row>
                                </Col>
                                <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                    <Dropdown as={ButtonGroup} align="end" >
                                        <Button
                                            style={{ textWrap: "nowrap" }}
                                            variant="warning"
                                            onClick={() => { handleAddSchemaClick(null, sectionData[0].Scheme); }}
                                        >
                                            <LuPlus />&nbsp;New
                                        </Button>

                                        <Dropdown.Toggle split variant="outline-secondary" className="border-secondary" />
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => { handleAddSchemaClick(sectionData[0], sectionData[0].Scheme) }}>
                                                <div style={{ textAlign: "center" }}><LuPlus /> Source</div>
                                            </Dropdown.Item>
                                            <button className="dropdown-delete"
                                                onClick={() => handleDelete(sectionData[0].Id)}>
                                                <LuTrash2 /> Delete
                                            </button>
                                            <Dropdown.Divider />
                                            <AccordionToggle className="dropdown-item-toggle" style={{ padding: "4px 16px" }} eventKey={sectionData[0].Scheme}>
                                                Sources &&nbsp;History
                                            </AccordionToggle>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {deleteId === sectionData[0].Id ? updateSuccess : ""}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion.Collapse eventKey={sectionData[0].Scheme}>
                    <Card.Body>
                        {/* Data Body */}
                        <Row><AccordionToggle className="acc-item-toggle rounded" style={{ padding: "4px" }} eventKey={sectionData[0].Scheme}><LuChevronUp fontSize="24px" /></AccordionToggle></Row>
                        <Row>
                            {/*Current sources*/}
                            <Col style={{ minWidth: "50%"}}>
                                <Row className="border-3 mb-2 p-0"
                                    style={{
                                        fontWeight: 700
                                    }}><div className="my-2" style={{
                                        width: "100%",
                                        textAlign: "center",
                                        textDecoration: "underline"
                                    }}>Sources</div></Row>
                                <div style={{ maxHeight: "66vh", overflowY: "auto", overflowX:"hidden"}}>
                                    {sectionData.map((entry) => (
                                        <Row key={entry.Id}>
                                            {entry.Value === sectionData[0].Value &&
                                                <Col className="mb-3" style={{minWidth:"175px"}}>
                                                    <Row className="mb-2 align-items-center">
                                                        <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Source</Col>
                                                        <Col style={{ minWidth: "90%" }}>
                                                            <div className="border rounded p-1" style={{ textWrap: "nowrap", overflowX: "auto", maxWidth: "100%" }}><Link to={entry.source}>{entry.Source ?? ""}</Link></div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-2 align-items-center">
                                                        <Col style={{ minWidth: "70%" }}>
                                                            <Row>
                                                                <Col style={{ minWidth: "50%" }}>
                                                                    <Row>
                                                                        <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Created</Col>
                                                                        <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{entry.UpdatedAt}</Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col>
                                                                    <Row>
                                                                        <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>by&nbsp;User</Col>
                                                                        <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{entry.VerifiedBy}</Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col style={{ textAlign: "right" }}>
                                                            <Button
                                                                className="px-4"
                                                                variant="danger"
                                                                onClick={() => handleDelete(entry.Id)}
                                                            >
                                                                <LuTrash2 />
                                                            </Button>
                                                            {deleteId === entry.Id ? updateSuccess : ""}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                            {/*Past Entries*/}
                            <Col style={{ minWidth: "175px" }}>
                                <Row className="mb-2 align-items-center"
                                    style={{
                                        fontWeight: 700
                                    }}><div className="my-2" style={{
                                        width: "100%",
                                        textAlign: "center",
                                        textDecoration: "underline"
                                    }}>History</div></Row>
                                <div style={{ maxHeight: "66vh", overflowY: "auto", overflowX: "hidden" }}>
                                    {sectionData.map((entry) => (
                                        <Row key={entry.Id + "Right"} className="mb-3">
                                            <Col>
                                                <Row className="mb-2 align-items-center">
                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Value</Col>
                                                    <Col style={{ minWidth: "90%" }}>
                                                        <div className="border rounded p-1" style={{ maxHeight: "55px", overflowY: "auto", overflowX: "hidden" }}>{entry.Value ?? ""}</div>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center">
                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Source</Col>
                                                    <Col style={{ minWidth: "90%" }}>
                                                        <div className="border rounded p-1 mb-2" style={{ textWrap: "nowrap", overflowX: "auto" }}><Link to={entry.source}>{entry.Source ?? ""}</Link></div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2 align-items-center">
                                                    <Col style={{ minWidth: "70%" }}>
                                                        <Row>
                                                            <Col style={{ minWidth: "50%" }}>
                                                                <Row>
                                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>Created</Col>
                                                                    <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{entry.UpdatedAt}</Col>
                                                                </Row>
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <Col style={{ fontWeight: 700, textWrap: "nowrap" }}>by&nbsp;User</Col>
                                                                    <Col style={{ minWidth: "60%", textWrap: "nowrap" }}>{entry.VerifiedBy}</Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col style={{ textAlign: "right" }}>
                                                        <Button
                                                            className="px-4"
                                                            variant="danger"
                                                            onClick={() => handleDelete(entry.Id)}
                                                        >
                                                            <LuTrash2 />
                                                        </Button>
                                                        {deleteId === entry.Id ? updateSuccess : ""}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>)
        )
    }

    return (
        selectedSat && <>
            <ModalSchemaEntry
                sat={selectedSat}
                show={showAddSchemaModal}
                setShow={setShowAddSchemaModal}
                scheme={selectedScheme}
                schemeData={selectedSchemeData}
                onAddSchema={handleAddSchema}
            />
            <ModalNewSchema
                sat={selectedSat}
                show={showNewSchemaModal}
                setShow={setShowNewSchemaModal}
                onAddScheme={handleAddSchema}
            />
            <Modal
                className="sat-modal"
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{
                            fontSize: "24px"
                        }}>
                        Editing <b>{selectedSat?.Name} - ({selectedSat?.SatNo})</b>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className="text-center mb-4 pb-3">
                        <Col sm='3'></Col>
                        <Col>
                            <Carousel
                                showThumbs={false}
                            >
                                {(selectedSat?.getImages(selectedSat?.Data) &&
                                    selectedSat?.getImages(selectedSat?.Data).reverse().map((url) => (
                                        <Figure className="m-0" key={url}>
                                            <FigureImage
                                                key={url}
                                                style={{
                                                    height: "100%",
                                                    width: "auto",
                                                    maxHeight: "350px",
                                                    padding: 'auto',
                                                }}
                                                src={url} rounded
                                            />
                                        </Figure>
                                    ))
                                )}
                            </Carousel>
                        </Col>
                        <Col sm='3'></Col>
                    </Row>
                    <Row className="mb-4">
                        <span>
                            <Button
                                style={{ marginRight: "10px" }}
                                variant="warning"
                                onClick={() => handleAddSchemaClick()}
                            > <LuPlus /> Attach Default Schema</Button>
                        
                            <Button
                                variant="secondary"
                                onClick={() => setShowNewSchemaModal(!showNewSchemaModal)}
                            > <LuPlus /> Add New Schema</Button>
                        </span>
                    </Row>

                    {/* Data Start */}
                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Images</Col>
                        <Col className="float-end text-end">
                            <Button
                                style={{ textWrap: "nowrap" }}
                                variant="warning"
                                onClick={() => { handleAddSchemaClick(null, "image"); }}
                            >
                                <LuPlus /> New Image
                            </Button>
                        </Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {getScheme(schema, ["image"])[0].Value !== "N/A" ? sortSchema(getScheme(schema, ["image"])).map((img) =>
                            <Card
                                key={img.Id + img.Value}
                            >
                                <Card.Header className="border-0 py-4">
                                    <Row
                                        className="align-items-center align-text-center"
                                        style={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        <Col style={{ minWidth: "50%" }}>
                                            <Row className="align-items-center align-text-center">
                                                <Col style={{ minWidth: "150px" }} className="py-2">{img.Scheme}</Col>
                                                <Col style={{ minWidth: "80%" }}>
                                                    <div style={{ maxWidth: "100%", textWrap: "nowrap" }} className="py-2">{img.Value
                                                        ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto"}}>
                                                            <Link to={img.Value}>{img.Value}</Link></div>
                                                        : ""}
                                                    </div>
                                                    <div style={{ maxWidth: "100%", display: "inline-flex", textWrap: "nowrap" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                                        {ignoreSource.findIndex(s => s.toLowerCase() === img.Source) === -1
                                                            ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>
                                                                {img.Source ? <Link to={img.Source}>{img.Source}</Link> : <span style={{color:"red"}}>Missing Source</span>}</div>
                                                            : <div className="p-1" style={{ fontWeight: 500, overflowX: "auto" }}>{img.Source}</div>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{ minWidth: "175px" }}>
                                            <Row className="align-items-center align-text-center">
                                                <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                                    <Row>
                                                        <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{(img.UpdatedAt)}</Col></Row></Col>
                                                        <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{img.VerifiedBy}</Col></Row></Col>
                                                    </Row>
                                                </Col>
                                                <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                                    <Dropdown as={ButtonGroup} align="end">
                                                        <AccordionToggle className="btn btn-warning dropdown-item-toggle" eventKey={img.Value}>
                                                            Preview
                                                        </AccordionToggle>
                                                        <Dropdown.Toggle split variant="outline-secondary"/>
                                                        <Dropdown.Menu>
                                                            <button className="dropdown-delete"
                                                                disabled={ignoreSource.findIndex(s => s === img.Source) !== -1}
                                                                onClick={() => handleDelete(img.Id)}>
                                                                <LuTrash2 /> Delete
                                                            </button>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {deleteId === img.Id ? updateSuccess : ""}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Accordion.Collapse eventKey={img.Value}>
                                    <Card.Body>
                                        <Row className="mb-3"><AccordionToggle className="acc-item-toggle rounded" style={{ padding: "4px" }} eventKey={img.Value}><LuChevronUp fontSize="24px" /></AccordionToggle></Row>
                                        <Row>
                                            <Image src={img.Value}
                                                style={{
                                                    maxHeight: "300px",
                                                    objectFit: "contain",
                                                }}
                                            />
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        )
                            : <Card><Card.Header className="border-bottom-0">Placeholder Image</Card.Header></Card>}

                    </Accordion>
                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Summary</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["overall_summary"])))}

                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Launch Details</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_summary"])))}
                        {getSchemeFromSection(selectedSat?.Data.filter((dat) => dat.Section === sections[1])).map((s) => 
                            renderAccordionItems(sortSchema(getScheme(schema, [s])))
                        )}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["launch_history_for_similar"])))}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Mission Details</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["details_description"])))}
                        {getSchemeFromSection(selectedSat?.Data.filter((dat) => dat.Section === sections[2])).map((s) => 
                            renderAccordionItems(sortSchema(getScheme(schema, [s])))
                        )}
                        {renderAccordionItems(sortSchema(getScheme(schema, ["assoc_sats"])),
                            (element) => { return element.Value.indexOf(" ") === -1 ? element.Value.replaceAll(",", ", ") : element.value })}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Characteristics</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {getSchemeFromSection(selectedSat?.Data.filter((dat) => dat.Section === sections[3])).map((s) => 
                            renderAccordionItems(sortSchema(getScheme(schema, [s])))
                        )}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Frequencies & Comms</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["comms_description"])))}
                        {getSchemeFromSection(selectedSat?.Data.filter((dat) => dat.Section === sections[4])).map((s) => 
                            renderAccordionItems(sortSchema(getScheme(schema, [s])))
                        )}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>Satellite Status</Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {renderAccordionItems(sortSchema(getScheme(schema, ["status_description"])))}
                        {getSchemeFromSection(selectedSat?.Data.filter((dat) => dat.Section === sections[5])).map((s) => 
                            renderAccordionItems(sortSchema(getScheme(schema, [s])))
                        )}
                    </Accordion>

                    <Row className="mb-4"
                        style={{ fontSize: "24px", color: "#ffc107" }}>
                        <Col>WEZ Info</Col>
                        <Col className="float-end text-end">
                            <Button
                                style={{ textWrap: "nowrap" }}
                                variant="warning"
                                onClick={() => { handleAddSchemaClick(null, "wez"); }}
                            >
                                <LuPlus /> New WEZ Card
                            </Button>
                        </Col>
                    </Row>
                    <Accordion
                        className="mb-4"
                        variant="dark"
                        activeKey={expandedItem}
                        onSelect={e => handlePersistAccordion(e)}>
                        {getSection(schema, "WEZ_INFO", false)[0].Value !== "N/A" ? getSection(schema, "WEZ_INFO", false).reverse().map((w) => {
                            return <Card
                                key={w.Id + w.Value}
                            >
                                <Card.Header className="border-0 py-4">
                                    <Row
                                        className="align-items-center align-text-center"
                                        style={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        <Col style={{ minWidth: "50%" }}>
                                            <Row className="align-items-center align-text-center">
                                                <Col style={{ minWidth: "150px" }} className="py-2">{w.Scheme}</Col>
                                                <Col style={{ minWidth: "80%" }}>
                                                    <div style={{ maxWidth: "100%"}} className="py-2">{w.Value
                                                        ? <>
                                                            <Row className="mb-2">
                                                            <Col><div className="border rounded p-1" style={{ fontWeight: 500, overflowY: "auto", maxHeight: "110px", width: "100%", whiteSpace:"preserve-breaks"}}>
                                                            {wezInfoText(w)}</div></Col>
                                                        </Row></>
                                                        : <></>}
                                                    </div>
                                                    <div style={{ maxWidth: "100%", display: "inline-flex", textWrap: "nowrap" }} className="py-2"><div className="py-1 pr-1">Source:&nbsp;</div>
                                                        {ignoreSource.findIndex(s => s.toLowerCase() === w.Source) === -1
                                                            ? <div className="border rounded p-1" style={{ fontWeight: 500, overflowX: "auto" }}>
                                                                {w.Source ? <Link to={w.Source}>{w.Source}</Link> : <span style={{color:"red"}}>Missing Source</span>}</div>
                                                            : <div className="p-1" style={{ fontWeight: 500, overflowX: "auto" }}>{w.Source}</div>}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col style={{ minWidth: "175px" }}>
                                            <Row className="align-items-center align-text-center">
                                                <Col style={{ minWidth: "55%", textWrap: "nowrap" }}>
                                                    <Row>
                                                        <Col style={{ minWidth: "50%", textWrap: "nowrap"  }} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>Updated</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{(w.UpdatedAt)}</Col></Row></Col>
                                                        <Col style={{ minWidth: "175px", textWrap: "nowrap"}} className="py-2"><Row><Col style={{minWidth:"88px", maxWidth:"33%"}}>by User</Col><Col style={{ minWidth:"175px", fontWeight:"500"}}>{w.VerifiedBy}</Col></Row></Col>
                                                    </Row>
                                                </Col>
                                                <Col style={{ textAlign: "center", maxWidth: "fit-content"}} className="px-4">
                                                    <Button variant="danger"
                                                        disabled={ignoreSource.findIndex(s => s === w.Source) !== -1}
                                                        onClick={() => handleDelete(w.Id)}>
                                                        <LuTrash2 /> Delete
                                                    </Button>
                                                    {deleteId === w.Id ? updateSuccess : ""}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Header>
                            </Card>
                        }
                        )
                            : <Card><Card.Header className="border-bottom-0">No WEZ Data</Card.Header></Card>}
                    </Accordion>

                </Modal.Body>
                {showFooter ? (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            className="decline-delete-button"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                ) : (<></>)}

            </Modal>
        </>
    );
}

export default ModalEditData;
