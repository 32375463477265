import React from "react";
import { Button } from 'react-bootstrap';

const OrbitalView = ({ satellites, useIframe = false }) => {
    const baseUrl = "https://spacecockpit.saberastro.com/?";
        
    const cleanSatelliteNumbers = (sats) => {
        return sats
            .flatMap(sat => {
                const matches = sat.toString().match(/\d+/g) || [];
                return matches.filter(num => parseInt(num) >= 51);
            })
            .filter((value, index, self) => self.indexOf(value) === index);
    };

    const cleanedSatellites = cleanSatelliteNumbers(satellites);
    const satParams = `SID=${cleanedSatellites.join(',')}`;
    const fullUrl = baseUrl + satParams;

    const openInNewTab = () => {
        window.open(fullUrl, '_blank', 'noopener,noreferrer');
    };

    if (useIframe) {
        return (
            <div style={{ width: '100%', height: '900px'}}>
                <iframe
                    src={fullUrl}
                    title="Orbital View"
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                />
            </div>
        );
    }

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <p>Click the button below to render orbital view:</p>
            <Button 
                variant="primary" 
                onClick={openInNewTab}
                style={{
                    backgroundColor: '#007bff',
                    border: 'none',
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease'
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
                onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
            >
                Render orbital view.
            </Button>
        </div>
    );
};

export default OrbitalView;