export const schemaTable = {
    bus: {
        section: "CHARACTERISTICS",
        description: "Name of bus",
        label: "Bus",
        rows: 1
    },
    expected_life: {
        section: "MISSION_DETAILS",
        description: "Expected life of the satellite in years",
        label: "Expected Life",
        rows: 1
    },
    comms_description: {
        section: "SUMMARIES_AND_MISC",
        description: "Brief paragraph about frequencies and communications. Include info about military usage if applicable.",
        label: "Comms Description",
        rows: 3
    },
    details_description: {
        section: "SUMMARIES_AND_MISC",
        description: "Brief paragraph about mission details such as purpose and goals. Include relevant information about military usage and if mission involves other satellites.",
        label: "Details Description",
        rows: 3
    },
    freq_band: {
        section: "FREQUENCIES_AND_COMMS",
        description: "Frequency band(s) of the satellite.",
        label: "Frequency Band",
        rows: 1
    },
    itu_name: {
        section: "MISSION_DETAILS",
        description: " International Telecommunication Union name of satellite. Sometimes called COSPAR ID, NSSDCA, or Intl Designator. Note format required when entering data.",
        label: "ITU Name",
        rows: 1
    },
    launch_date: {
        section: "LAUNCH_DETAILS",
        description: "Date of launch - Date and time in UTC. If you are unsure about time, use 00:00:00.",
        label: "Launch Date",
        rows: 1
    },
    launch_mass: {
        section: "LAUNCH_DETAILS",
        description: "Launch mass of the satellite in kg. Do not include payload mass.",
        label: "Launch Mass",
        rows: 1
    },
    launch_vehicle: {
        section: "LAUNCH_DETAILS",
        description: "Usually the name of rocket body that satellite is launched on such as Long March 3B or Falcon 9.",
        label: "Launch Vehicle",
        rows: 1
    },
    name: {
        section: "MISSION_DETAILS",
        description: "Additional names of the satellite (such as spelling or language variations).",
        label: "Additional names",
        rows: 1
    },
    operator: {
        section: "MISSION_DETAILS",
        description: "Usually the company that built the satellite or the government agency that launched it.",
        label: "Operator",
        rows: 1
    },
    overall_summary: {
        section: "SUMMARIES_AND_MISC",
        description: "Summary of the satellite, where it's from, type, purpose, alternate names, etc. Include associated satellites if applicable.",
        label: "Overall Summary",
        rows: 3
    },
    owner: {
        section: "MISSION_DETAILS",
        description: "Usually country or company that launched the satellite",
        label: "Owner",
        rows: 1
    },
    payload_manufacturer: {
        section: "MISSION_DETAILS",
        description: "Company that built the payload.",
        label: "Payload Manufacturer",
        rows: 1
    },
    polarization: {
        section: "FREQUENCIES_AND_COMMS",
        description: "Polarization of the satellite.",
        label: "Polarization",
        rows: 1
    },
    primary_mission: {
        section: "MISSION_DETAILS",
        description: "Primary purpose of the satellite (e.g. communications, surveillance, etc.). Military missions are usually prioritized.",
        label: "Primary Mission",
        rows: 2
    },
    propulsion: {
        section: "CHARACTERISTICS",
        description: "Type of propulsion system used on the satellite such as chemical, solar, or electric.",
        label: "Propulsion",
        rows: 1
    },
    rcs_body_dimension: {
        section: "CHARACTERISTICS",
        description: "RCS body dimension of the satellite in meters squared. Also include descriptors such as'large' or 'small' if applicable.",
        label: "RCS Body Dimension",
        rows: 1
    },
    size: {
        section: "CHARACTERISTICS",
        description: "Size of the satellite in meters. Specify dimensions such as length, width, or height (if applicable).",
        label: "Size",
        rows: 1
    },
    status_description: {
        section: "SUMMARIES_AND_MISC",
        description: "Description of the satellite's current status (pre-set options).",
        label: "Status Description",
        rows: 1
    },
    ttc_downlink_freqs: {
        section: "FREQUENCIES_AND_COMMS",
        description: "Telemetry, Tracking, and Command (TTC) downlink frequencies of the satellite. Can be S-band or specified frequencies (in Ghz).",
        label: "TTC Downlink Frequencies",
        rows: 1
    },
    ttc_uplink_freqs: {
        section: "FREQUENCIES_AND_COMMS",
        description: "Telemetry, Tracking, and Command (TTC) uplink frequencies of the satellite. Can be S-band or specified frequencies (in Ghz).",
        label: "TTC Uplink Frequencies",
        rows: 1
    },
    image: {
        section: "SUMMARIES_AND_MISC",
        description: "Image of the satellite or launch vehicle. Value should be the URL of the image and source should be where the image was found. Note that gifs ARE supported.",
        label: "Image",
        rows: 1
    },
    payload: {
        section: "CHARACTERISTICS",
        description: "Type of payload on the satellite.",
        label: "Payload",
        rows: 1
    },
    launch_history_for_similar: {
        section: "SUMMARIES_AND_MISC",
        description: "Launch history for similar satellites (few sentences).",
        label: "Launch History for Similar",
        rows: 2
    },
    frequency: {
        section: "FREQUENCIES_AND_COMMS",
        description: "Frequency of the satellite in Hz or GHz.",
        label: "Frequency",
        rows: 1
    },
    secondary_mission: {
        section: "MISSION_DETAILS",
        description: "Secondary purpose of the satellite (e.g. communications, surveillance, etc.).",
        label: "Secondary Mission",
        rows: 2
    },
    status: {
        section: "MISSION_DETAILS",
        description: "Current operational status of the satellite",
        label: "Status",
        rows: 1
    },
    decay_date: {
        section: "MISSION_DETAILS",
        description: "Date when the satellite decayed or is expected to decay from orbit",
        label: "Decay Date",
        rows: 1
    },
    wez: {
        section: "WEZ_INFO",
        description: "Weapon engagement zone details",
        label: "WEZ",
        rows: 1
    },
    launch_site: {
        section: "LAUNCH_DETAILS",
        description: "Location where the satellite was launched from",
        label: "Launch Site",
        rows: 1
    },
    manufacturer: {
        section: "CHARACTERISTICS",
        description: "Company or organization that manufactured the satellite",
        label: "Manufacturer",
        rows: 1
    },
    mission_life: {
        section: "MISSION_DETAILS",
        description: "Expected operational lifetime of the satellite",
        label: "Mission Life",
        rows: 1
    },
    mass: {
        section: "CHARACTERISTICS",
        description: "Current mass of the satellite in kilograms",
        label: "Mass",
        rows: 1
    },
    mission_type: {
        section: "MISSION_DETAILS",
        description: "Type of mission the satellite is designed for",
        label: "Mission Type",
        rows: 1
    },
    payload_description: {
        section: "CHARACTERISTICS",
        description: "Detailed description of the satellite's payload and capabilities",
        label: "Payload Description",
        rows: 3
    },
    separation_events: {
        section: "LAUNCH_DETAILS",
        description: "Details about separation events during launch",
        label: "Separation Events",
        rows: 1
    },
    dimensions: {
        section: "CHARACTERISTICS",
        description: "Physical dimensions of the satellite",
        label: "Dimensions",
        rows: 1
    },
    power: {
        section: "CHARACTERISTICS",
        description: "Power specifications and generation capabilities",
        label: "Power",
        rows: 1
    },
    is_military: {
        section: "MISSION_DETAILS",
        description: "Whether the satellite has military applications or not",
        label: "Military Usage",
        rows: 1
    },
    assoc_sats: {
        section: "SUMMARIES_AND_MISC",
        description: "Associated satellites (comma-separated list of satellite numbers)",
        label: "Associated Satellites",
        rows: 1
    }
}