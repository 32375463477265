import React, { useState, createContext, useContext, useEffect } from 'react';
import { MsgTypes } from '../utils/Websockets';
import { updateSatellites } from '../utils/Utils';

const UsernameContext = createContext();
export const useUser = () => useContext(UsernameContext);

export const UsernameProvider = ({ children }) => {
    const [satellites, setSatellites] = useState({});
    const [username, setUsername] = useState("Guest");
    const [newMsg, setNewMsg] = useState(null);
    const [ws, setWs] = useState(null);

    useEffect(()=>{
        if (!newMsg) return;
        if (newMsg.id === MsgTypes.Select) {
            setSatellites(updateSatellites(newMsg.data, satellites));
        } else if (
            newMsg.id === MsgTypes.SaveScheme
            || newMsg.id === MsgTypes.SavePOI
        ) {
            // assumes one insert at a time
            ws.getPsraData(newMsg.data.lastInsertId);
        } else if (newMsg.id.split("-")[0] === MsgTypes.UpdatePsra) { // UpdatePsra-<updateRowId> for selecting new updates
            // assumes one update at a time
            ws.getPsraData(newMsg.id.split("-")[1], true);
        } else if (newMsg.id === MsgTypes.SelectUpdate) {
            setSatellites(updateSatellites(newMsg.data, satellites));
        } else if(newMsg.id === MsgTypes.SaveSatellite) {
            setSatellites(updateSatellites(newMsg.data, satellites));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newMsg]);

    return (
        <UsernameContext.Provider value={{ 
            username, 
            setUsername,
            newMsg,
            setNewMsg,
            ws,
            setWs,
            satellites,
        }}>
            {children}
        </UsernameContext.Provider>
    );
};
