import React, {useRef, useState, useEffect} from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

const OverlayElement = ({
    text,
    element,
    placement = "right"
}) => {
    const target = useRef(null);
    const display = useRef(null);
    const [show, setShow] = useState(false);
  
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (event) => {
            if (display.current && !display.current.contains(event.target)) {
                setShow(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [display]);

    return (
        <>
            <span
                ref={target}
                onClick={() => setShow(true)}
                style={{cursor:"pointer"}}
            >
                {text}
            </span>

            <Overlay 
                target={target.current} 
                show={show} 
                placement={placement}
            >
                <Tooltip className='dark-overlay'>
                    <div className="text-nowrap" ref={display}>
                        {element}
                    </div>
                </Tooltip>
            </Overlay>
        </>
    );
};

export default OverlayElement;
