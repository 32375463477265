import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Modal, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sortBy } from 'lodash';
import { useUser } from "../contexts/user";
//components
import SatelliteCard from '../components/SatelliteCard';
import SpacefanaDashboard from '../components/SpacefanaDashboard';
import OrbitalView from "../components/OrbitalView";
import Poi from '../components/Poi';
import ModalEditData from '../components/ModalEditDataV2.0';
import NotFound from '../components/NotFound';
import OverlayElement from '../components/OverlayElement';
//utils
import { timeDifference, getScheme, getSection, isLoggedIn } from '../utils/Utils';
//css
import "../css/OverlayElement.css";
//icons
import { HiOutlineCube } from "react-icons/hi2";
import { CgDetailsMore } from "react-icons/cg";
import { GiLightningFrequency } from "react-icons/gi";
import { MdOutlineSatelliteAlt } from "react-icons/md";
import { FiEdit } from 'react-icons/fi';
import { GiChart } from 'react-icons/gi';
import { FaCrosshairs } from 'react-icons/fa';
import { LiaRocketSolid } from "react-icons/lia";
import { GiOrbit } from "react-icons/gi";
import { LuChevronUp, LuChevronDown, LuInfo, LuOrbit } from "react-icons/lu";

const Dossier = () => {
    const { username, satellites } = useUser();
    const { id } = useParams();
    const [notFound, setNotFound] = useState(false);
    const [sat, setSat] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSpacefanaModal, setShowSpacefanaModal] = useState(false);
    const [associatedSatellites, setAssociatedSatellites] = useState([]);
    const [showAssocSatList, toggleShowAssocSatList] = useState(false);
    const [showOrbitalViewModal, setShowOrbitalViewModal] = useState(false);

    // Automatically renders schema in two columns based on section (for regular sections)
    const renderDetailsInTwoColumns = (detailsArray) => {
        if(detailsArray[0].Value === "N/A"){return <>No Data</>}
        const midpoint = Math.ceil(detailsArray.length / 2);
        const leftColumn = detailsArray.slice(0, midpoint);
        const rightColumn = detailsArray.slice(midpoint);

        return (
            <Row>
                <Col md={6}>
                    {leftColumn.map((schema) => (
                        <Row key={`${schema.Id}-${schema.Scheme}`} style={{marginTop: "10px"}}>
                            <Col sm="4">{schema.Label}</Col>
                            <Col sm="7">{getSourceAndValue([schema])}</Col>
                        </Row>
                    ))}
                </Col>
                <Col md={6}>
                    {rightColumn.map((schema) => (
                        <Row key={`${schema.Id}-${schema.Scheme}`} style={{marginTop: "10px"}}>
                            <Col sm="4">{schema.Label}</Col>
                            <Col sm="7">{getSourceAndValue([schema])}</Col>
                        </Row>
                    ))}
                </Col>
            </Row>
        );
    };

    // Auto renders cards for WEZ data
    const renderWezInfo = (wezData) => {
        if(wezData[0].Value === "N/A"){return <><div>No Data</div></>}
        return (
            wezData.reverse().map((wez, index) => {
                let parseVal;
                if (typeof wez.Value === "string") {
                    try {
                        parseVal = JSON.parse(wez.Value);
                    } catch (error) {
                        console.error("Failed to parse detail.Value as JSON:", error);
                        parseVal = {};
                    }
                }
                const keys = Object.keys(parseVal);
                return (
                    <Col key={`wez-${index}-${wez.Scheme}-${parseVal.name}`} className="mb-3" sm={12} lg={6}>
                        <Card style={{height:"220px", overflowY:"auto"}}>
                            <Card.Body style={{paddingInline:"1.5rem"}}>
                                <Row className="mb-2">
                                    <Col style={{ minWidth: "75%" }}>
                                        <Card.Title style={{ paddingTop: "10px", fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <span>
                                                {wez.Scheme}<sup className='style-source'>
                                                    <OverlayElement
                                                        text={` [Source] `} // "[Source]" or "[Source] (<#>)"
                                                        element={<div className="px-1"> <Link className="style-link" to={wez.Source}>{wez.Source}</Link><br /></div>}

                                                    />
                                                </sup>
                                            </span>
                                        </Card.Title>
                                        <hr style={{ margin: '10px 0' }} />
                                        {/* Name */}
                                        <Row style={{ marginBottom: "15px" }}>
                                            <Col><b>{parseVal.name}</b></Col>
                                        </Row>
                                    </Col>
                                    
                                    <Col style={{ minWidth: "fit-content", textAlign: "center" }}>
                                        <Image
                                            src={(() => {
                                                try {
                                                    return require(`../assets/images/WEZIcons/${wez.Scheme}.png`);
                                                } catch {
                                                    return require('../assets/images/WEZIcons/Unknown.png');
                                                }
                                            })()}
                                        /> 
                                    </Col>
                                </Row>
                                <Row>
                                    <Col><Row>
                                        {keys.slice(1).map((key, keyIndex) => ( // Exclude the first element (Name)
                                            <Col xs={12} sm={6} key={`wez-detail-${index}-${wez.Scheme}-${key}-${keyIndex}`}>
                                                <div style={{display:"inline-flex", marginBottom:"1rem", borderBottom:"dotted 1px", borderBottomColor:"#ffffff26",}}>
                                                    <div>{key}: </div>
                                                    <div style={{ marginLeft:"1rem", paddingRight:"auto"}}>{parseVal[key]}</div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row></Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                )
            })
        )
    };

    useEffect(() => {
        if (sat?.Data) {
            const assocSats = sortSchema(getScheme(sat.Data, ["assoc_sats"]))[0].Value;
            if (assocSats !== "N/A") {
                setAssociatedSatellites(assocSats.split(',').map(s => s.trim()));
            } else {
                setAssociatedSatellites([]);
            }
        }
    }, [sat?.Data]);

    useEffect(() => {
        if (satellites[id]) {
            setNotFound(false);
            return setSat({ ...satellites[id] });
        } else if (Object.keys(satellites).length > 0) {
            setNotFound(true);
        }
    }, [id, satellites, setSat]);

    const toggleEditModal = () => setShowEditModal(!showEditModal);

    const toggleSpacefanaModal = () => {
        setShowSpacefanaModal(!showSpacefanaModal);
    }

    const toggleOrbitalViewModal = () => {
        setShowOrbitalViewModal(!showOrbitalViewModal);
    };

    const associatedSatNames = (assocSat) => satellites[parseInt(assocSat)]?.Name ? satellites[parseInt(assocSat)].Name : " ";

    /// Sorts schema by UpdatedAt column
    const sortSchema = (unsortSchema) => {
        return sortBy(unsortSchema, Date.parse(unsortSchema.UpdatedAt)).reverse();
    }

    /// returns ordered html of source links
    const getSource = (sortedSchema) => {
        if (!sortedSchema[0].Source) {
            return sortedSchema[0].Value !== "N/A"
            ?  <>
                    <sup style={{ color:"red" }}>
                        <OverlayElement 
                            text={` [Source] `}
                            element={<div className="ps-1">No source provided.</div>}
                        />
                    </sup>
                </>
            : <></>
        }
        let sourceText = [];
        sortedSchema.forEach(scheme => {if (scheme.Value === sortedSchema[0].Value) {sourceText.push(scheme.Source)}})
        return  <>
                    <sup className='style-source'>
                        <OverlayElement 
                            text={` [Source] ${sourceText.length === 1 ? "" : `(${sourceText.length})`}`}
                            element={sourceText.map((src, id) => (
                                <div key={`source-${id}-${src}`} className="ps-1">
                                    <Link className="style-link" to={src}>{src}</Link>
                                    <br/>
                                </div>
                            ))}
                        />
                    </sup>
                </>;
    }

    /// Wrapper to get most recent scheme value and sources.
    const getSourceAndValue = (schema) => {
        const sortedSchema = sortSchema(schema);
        return <>
            {sortedSchema[0].Value}{getSource(sortedSchema)}
        </>;
    }


    return (
        <>
            {notFound && (
                <NotFound
                    id={id}
                >
                </NotFound>
            )}
            {sat?.Data && (

                <div style={{ padding: "20px" }}>
                    <Helmet>
                        <title>({sat.SatNo}) {sat.Name}</title>
                        <meta name="satellite-name" content={sat ? `Satellite: ${sat.Name}` : "Satellite Details"} />
                        <meta property="description" content={sat ? sat.getDescription(sat.Data) : "No satellite data available"} />
                    </Helmet>
                    <h2>{sat.SatNo} - {sat.Name}</h2>
                    <br></br>
                    <Row>
                        <Col md={8} className="mb-3">

                            {/* Edit Data Button */}
                            {isLoggedIn(username) && (
                                <div className="mb-2" style={{ display: 'flex', gap: '20px' }}>
                                    <Button variant="outline-primary" onClick={toggleEditModal} style={{ borderColor: 'white', color: 'white' }}>
                                        <FiEdit /> Edit Data
                                    </Button>
                                    <Button onClick={toggleSpacefanaModal} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                        <GiChart /> Elset History
                                    </Button>
                                    <Button onClick={toggleOrbitalViewModal} variant="outline-primary" style={{ borderColor: 'white', color: 'white' }}>
                                        <LuOrbit /> Orbital View
                                    </Button>
                                </div>
                            )}
                            <div style={{ fontStyle: "italic", color: "#ffc107", marginTop: "15px", marginBottom: "15px" }}>
                                <LuInfo />
                                This is publicly available information and was not provided through Intel channels.
                            </div>
                            {/* Edit Modal Data */}
                            <ModalEditData
                                show={showEditModal}
                                setShow={setShowEditModal}
                                selectedSat={sat}
                            />

                            {/* Summary */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title id="summary" style={{ color: "#ffc107" }}>
                                        Summary
                                    </Card.Title>
                                    {getSourceAndValue(getScheme(sat?.Data, ["overall_summary"]))}
                                </Card.Body>
                            </Card>

                            {/* Orbit */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><GiOrbit /> Orbit</Card.Title>
                                    <Row>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Last Seen (18th SPCS)</Col>
                                                <Col>[{timeDifference(sat?.Epoch)}] {sat?.Epoch}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Inclination</Col>
                                                <Col>{sat?.Inclination}</Col>
                                            </Row>

                                        </Col>
                                        <Col sm="6">
                                            <Row>
                                                <Col sm="4">Longitude (°E)</Col>
                                                <Col>{sat?.Longitude}</Col>
                                            </Row>
                                            <Row>
                                                <Col sm="4">Longitude Drift (°E/day)</Col>
                                                <Col>{sat?.LonDriftDegreesPerDay}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* lAUNCH_DETAILS Automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><LiaRocketSolid /> Launch Details</Card.Title>
                                    <Row className="border-bottom mt-2 pt-2" style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                        <Col>
                                        {getScheme(sat?.Data, ["launch_summary"])[0].Value === "N/A"
                                            ? ""
                                            : getSourceAndValue(getScheme(sat?.Data, ["launch_summary"]))}
                                        </Col>
                                    </Row>
                                    
                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "LAUNCH_DETAILS"))}

                                    <Row className="border-top mt-2 pt-2">
                                        <Col sm="2">Launch History For Similar</Col>
                                        <Col>{getSourceAndValue(getScheme(sat?.Data, ["launch_history_for_similar"]))}</Col>
                                    </Row>

                                </Card.Body>
                            </Card>

                            {/* MISSION_DETAILS Automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><CgDetailsMore /> Mission Details</Card.Title>
                                    {getScheme(sat?.Data, ["details_description"])[0].Value !== "N/A" && (
                                        <div className="border-bottom mt-2 pt-2" style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                            {getSourceAndValue(getScheme(sat.Data, ["details_description"]))}
                                        </div>
                                    )}
                                    
                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "MISSION_DETAILS"))}
                                    
                                    {/* Associated Satellites */}
                                    <Row className="border-top" style={{ marginTop: '.6rem', paddingTop: '.7rem' }}>
                                        <Col sm="2">
                                            Associated Sat(s) {getSource(getScheme(sat?.Data, ["assoc_sats"]))}
                                        </Col>
                                        {(associatedSatellites.length >= 1) && (
                                            <Col sm="4">
                                                {(!showAssocSatList
                                                    ? associatedSatellites.slice(0, 3).map((ida) => (
                                                        <div key={`assoc-sat-${ida}`}>
                                                            <Link className="style-link" to={`/dossier/${ida}`}>{ida}</Link> - {associatedSatNames(ida)}
                                                        </div>
                                                    ))
                                                    : associatedSatellites.map((ida) => (
                                                        <div key={`assoc-sat-${ida}`}>
                                                            <Link className="style-link" to={`/dossier/${ida}`}>{ida}</Link> - {associatedSatNames(ida)}
                                                        </div>
                                                    )))}
                                                {(associatedSatellites.length > 3) &&
                                                    <>
                                                        {(!showAssocSatList) && (<div style={{ fontSize: ".75rem", color: 'lightGray' }}>+ {associatedSatellites.length - 3} more...</div>)}
                                                        <Button variant="outline-warning" className='mt-2 btn-outline-warning' onClick={() => toggleShowAssocSatList(!showAssocSatList)}>
                                                            {showAssocSatList ? <><LuChevronUp /> Collapse</> : <><LuChevronDown /> Expand</>}
                                                        </Button>
                                                    </>
                                                }
                                            </Col>
                                        )}
                                        {(associatedSatellites.length < 1) && (
                                            <Col sm="4">N/A</Col>
                                        )}
                                    </Row>
                                </Card.Body>
                            </Card>

                            {/* Characteristics automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><HiOutlineCube /> Characteristics</Card.Title>
                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "CHARACTERISTICS"))}
                                </Card.Body>
                            </Card>

                            {/* Frequencies and Comms automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><GiLightningFrequency /> Frequencies & Comms</Card.Title>
                                    {getScheme(sat?.Data, ["comms_description"])[0].Value !== "N/A" && (
                                        <div className="border-bottom mt-2 pt-2" style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                            {getSourceAndValue(getScheme(sat.Data, ["comms_description"]))}
                                        </div>
                                    )}

                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "FREQUENCIES_AND_COMMS"))}

                                </Card.Body>
                            </Card>

                            {/* Satellite Status automated */}
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107" }}><MdOutlineSatelliteAlt /> Satellite Status</Card.Title>
                                    {getScheme(sat?.Data, ["status_description"])[0].Value !== "N/A" && (
                                        <div className="border-bottom mt-2 pt-2" style={{paddingBottom: "10px", marginBottom: "5px"}}>
                                            {getSourceAndValue(getScheme(sat.Data, ["status_description"]))}
                                        </div>
                                    )}

                                    {renderDetailsInTwoColumns(getSection(sat?.Data, "SATELLITE_STATUS"))}

                                </Card.Body>
                            </Card>

                            {/* WEZ */}
                            {getSection(sat?.Data, "WEZ_INFO", false) && ( // Check if there is info to render
                            <Card className="mb-3">
                                <Card.Body>
                                    <Card.Title style={{ color: "#ffc107", paddingBottom: "10px" }}><FaCrosshairs /> Weapon Engagement Zones</Card.Title>
                                    <Row>
                                        {renderWezInfo(getSection(sat?.Data, "WEZ_INFO", false))}
                                    </Row>
                                </Card.Body>
                            </Card>
                            )}

                            {/* NEW MODAL FOR ELSET HISTORY */}
                            <Modal
                                show={showSpacefanaModal}
                                onHide={toggleSpacefanaModal}
                                size="xl"
                                aria-labelledby="spacefana-modal"
                                centered
                                dialogClassName="spacefana-modal-dialog"
                            >
                                <Modal.Header closeButton className="spacefana-modal-header">
                                    <h6>({sat?.SatNo}) {sat?.Name}</h6>
                                </Modal.Header>
                                <Modal.Body>
                                    {sat && (
                                        <SpacefanaDashboard
                                            satNumber={sat.SatNo}
                                            useIframe={true}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>

                            {/* Modal for Orbital View */}
                            <Modal
                                show={showOrbitalViewModal}
                                onHide={toggleOrbitalViewModal}
                                size="xl"
                                aria-labelledby="orbital-view-modal"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="orbital-view-modal">
                                        {sat?.Name}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {sat && (
                                        <OrbitalView
                                            satellites={[sat.SatNo, ...associatedSatellites]}
                                            useIframe={true}
                                        />
                                    )}
                                </Modal.Body>
                            </Modal>
                        </Col>

                        {/* Right Column */}
                        <Col
                            md={4}
                            className="mb-4"
                        >
                            {sat
                                ?
                                (
                                    <>
                                        <SatelliteCard
                                            sat={sat}
                                        />
                                        <Poi
                                            sat={sat}
                                        />
                                    </>
                                )
                                : (
                                    <></>)}
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default Dossier;
