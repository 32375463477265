import React, {useState, useEffect} from 'react';
import { useUser } from "../contexts/user";
import { Button, Row, Col, Form, Modal, Container} from 'react-bootstrap';
import { CiCircleCheck, CiCircleRemove } from "react-icons/ci";
import {LuTrash2} from "react-icons/lu"
import { TextField } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MsgTypes } from "../utils/Websockets";
import '../css/poi.css';

let timeoutId;

const ModalEditPoi = ({
    show,
    setShow,
    poi,
    onDelete,
}) => {
    const { ws, username, newMsg} = useUser();
    const [saving, setSaving] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [deleteId, setDeleteId] = useState(0)

    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        },
    });

    const handleClose = () => {
        setShow(false);
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this schema?\n\nNote: refresh may needed for changes to appear on dosier page.")) {
            ws.DeleteSatellite({ Id: id });
            
            setDeleteId(id);

            setUpdateSuccess(
                <span style={{ color: "#ffc107" }}>
                    <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleting...
                </span>
            );
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    };

    useEffect(() => {
        if (!newMsg) return;
        if (deleteId !== 0){
            if (newMsg.id === MsgTypes.DeleteSatellite) {
                setUpdateSuccess(
                    <span style={{ color: "#ffc107" }}>
                        <CiCircleCheck style={{ fontSize: "1.5rem" }} /> Deleted
                    </span>
                );
                onDelete(deleteId);
                setShow(false);
                setDeleteId("0")
            } else {
                setUpdateSuccess(
                    <span style={{ color: "red" }}>
                        <CiCircleRemove style={{ fontSize: "1.5rem" }} /> Error deleting, please try again.
                    </span>
                );
            }
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setUpdateSuccess(<></>), 2000);
        }
    }, [newMsg,onDelete,deleteId,setShow]);

    /* Updates PSRA and Timeline */
    const handleSubmit  = (e)=>{
        e.preventDefault();
        const data = new FormData(e.target);
        const id = data.get("id");
        const timestamp = data.get("timestamp");
        const source = data.get("source");
        const value = data.get("value");
        setSaving(true);
        setUpdateSuccess(<></>);
        ws.updatePsra(id,timestamp,source,value,username);
    };
return(
<ThemeProvider theme={darkTheme}>
                <Modal 
                className="poi-modal" 
                show={show} 
                setShow={setShow} 
                onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Edit POI
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body style={{padding:0, overflow:"hidden"}}>
                        <Container>
                            <Row className="pt-4">
                            <Form id={poi.Id} onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="poi">
                                    <Form.Control
                                        name="id"
                                        type="hidden"
                                        defaultValue={poi.Id}
                                        required
                                    />
                                    <TextField
                                        label="Event Timestamp" 
                                        type="text"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Timestamp ?? ""}
                                        name="timestamp" 
                                        placeholder="Timestamp" 
                                        required
                                    />
                                <Row><br></br></Row>

                                    <TextField
                                        label="Source (URL preferred)"
                                        color="warning" 
                                        style={{width:"100%"}}
                                        defaultValue={poi.Source ?? ""} 
                                        name="source" 
                                        placeholder="Source (URL preferred)" 
                                        required
                                    />
                                <Row><br></br></Row>
                                    <TextField
                                        label="POI Details"
                                        color="warning"
                                        style={{width:"100%"}}
                                        defaultValue={poi.Value ?? ""} 
                                        name="value" 
                                        placeholder="Value" 
                                        required
                                    />
                                <Row><br></br></Row>
                                    <div className="mb-2">
                                            Verified by: <b>{username}</b> 
                                    </div>
                                    <br/>
                                <Row>
                                    <Col>
                                        <Button variant="warning" 
                                            type="submit"
                                            disabled={saving} 
                                            >
                                                Update
                                            </Button>
                                    </Col>
                                    <Col className="text-end float-end">
                                        <Button
                                            className="px-4"
                                            variant="danger"
                                            onClick={() => handleDelete(poi.Id)}
                                        >
                                            <LuTrash2 /> Delete
                                        </Button>
                                    </Col>
                                </Row>
                                <Row><Col className="text-center float-center">{updateSuccess}</Col></Row>
                                </Form.Group>
                            </Form>
                            </Row>
                        </Container>

                    </Modal.Body>
                    
            </Modal>
            </ThemeProvider>
)
}
export default ModalEditPoi;