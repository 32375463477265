import React from "react";
import {Form} from "react-bootstrap";
import { TextField } from "@mui/material";
import '../css/poi.css';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { WezForm } from "./WezForms";
import { schemaTable } from "./SchemaTable";

const editableFieldStyle = {
    width: "100%"
};

export const isMilitaryForm = (defaultValue = '') => {
    return (
        <TextField
            select
            fullWidth
            name="value"
            label="Military Usage"
            defaultValue={defaultValue}
            required
            sx={editableFieldStyle}
        >
            <MenuItem value="Civilian">Civilian</MenuItem>
            <MenuItem value="Military">Military</MenuItem>
        </TextField>
    );
};

export const statusForm = () => {
    return (
        <Form.Group>
            <TextField
                className="mb-2"
                sx={editableFieldStyle}
                name="value"
                label="Current Satellite Status"
                select
                required
            >
                <MenuItem value="Operational">Operational</MenuItem>
                <MenuItem value="Nonoperational">Nonoperational</MenuItem>
                <MenuItem value="Decayed">Decayed</MenuItem>
                <MenuItem value="Graveyard">Graveyard</MenuItem>
                <MenuItem value="Debris">Debris</MenuItem>
                <MenuItem value="Rocket Body">Rocket Body</MenuItem>
            </TextField>
        </Form.Group>
    );
};

export const dateForm = (handleValue) => {
    return(
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en"
        >
            <DateTimePicker
                sx={editableFieldStyle}
                slotProps={{ 
                    textField: { 
                        fullWidth: true,
                        sx: editableFieldStyle
                    } 
                }}
                name="value"
                label="Date and Time (UTC)"
                timezone="UTC"
                className="mb-2"
                onChange={handleValue}
                disableFuture
                format="YYYY-MM-DD HH:mm:ss"
                required
                ampm={false}
            />
        </LocalizationProvider>
    );
};

export const textForm = (schema) => {
    const schemaInfo = schemaTable[schema];
    if (!schemaInfo) return null;

    const { label, description, rows } = schemaInfo;
    
    return (
        <TextField
            className="mb-2"
            sx={editableFieldStyle}
            name="value"
            label={label}
            placeholder={description}
            multiline
            minRows={rows}
            required
        />
    );
};

export const wezForm = (handleValue, handleType) => {
    return (
        <WezForm
            style={{width:"100%"}}
            onChange={handleValue}
            onTypeChange={handleType}
        />
    );
};