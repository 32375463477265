export const EDITABLE_SCHEMAS = [
    "bus",
    "expected_life",
    "comms_description",
    "details_description",
    "freq_band",
    "itu_name",
    "launch_date",
    "launch_mass",
    "launch_vehicle",
    "name",
    "operator",
    "overall_summary",
    "owner",
    "payload_manufacturer",
    "polarization",
    "primary_mission",
    "propulsion",
    "rcs_body_dimension",
    "size",
    "status_description",
    "ttc_downlink_freqs",
    "ttc_uplink_freqs",
    "image",
    "payload",
    "launch_history_for_similar",
    "frequency",
    "secondary_mission",
    "est_remaining_fuel",
    "launch_summary",
    "interim_orbits",
    "possible_anomalies",
    "itu_slot_projected",
    "launch_profile",
    "assoc_sats",
    "est_current_mass",
    "est_customers_supported",
    "launch_site_id",
    "separation_waypoint",
    "estimated_mission_capacity",
    "launch_id",
    "deviation_from_mission",
    "ttc_crosslink_freqs",
    "observed_foreign_mil_ties",
    "nomination_justification",
    "is_military",
];

export const REGIMES = {
    1: "Undetermined",
    2: "Leo",
    4: "Heo",
    8: "GeoInclined",
    16: "Meo",
    32: "Molniya",
    64: "Sso",
    128: "Polar",
    256: "GeoStationary",
    512: "Graveyard",
};

export const SPACE_COCKPIT_BASE_URL = "https://spacecockpit.saberastro.com/";

export const Wezs = {
    "Jammer": {
          "Custom":   {"name":"","Effective Range (km)": 0 },
    },

    "Laser": {
          "Custom":   {"name":"","Wattage (W)": 0, "Beam Divergence (deg)": 0, "Init. Cross-Section of Beam (mm^2)": 0},
    },

    "Lidar": {
          "Custom":   {"name":"","Effective Range (km)": 0},
    },

    "Net": {
          "Custom":   {"name":"","Diameter (m)": 0, "Max Range (m)": 0 , "Min Range (m)": 0 },
    },

    "Armature": {
          "Custom":   {"name":"","Reach (m)": 0, "Range of Movement (deg)": 0 },
    },

    "Projectile": {
          "Custom":   {"name":"","Effective Targeting Range (m)": 0 },
    },

    "Radar": {
          "Custom":   {"name":"","Effective Range (km)": 0 },
    },

    "Sprayer": {
          "Custom":   {"name":"","Effective Range (m)": 0, "Cone Angle (deg)": 0},
    },

    "Telescope": {
          "Custom":   {"name":"","Min. Focal Length (mm)": 0, "Max. Focal Length (mm)": 0, "Min. Focusing Distance (m)": 0, "Resolution X (px)": 0, "Resolution Y (px)": 0},
    },

    "Microwave": {
          "Custom":   {"name":"","Wattage (W)": 0, "Beamwidth (deg)": 0, "Cross-Sectional Area of Emitter (m^2)": 0},
    },
    "Explosive": {
          "Custom":   {"name":"","Effective Radius (m)": 0, "Angular Range of Effect (deg)": 0},
    },
}
