import _ from 'lodash';

export const debouncedSearch = _.debounce((searchValue, satellites, setFilteredSats) => {
    const searchItems = searchValue.split(',');
    const matches = searchItems.map((searchItem) => Object.values(satellites).filter(satellite => {
        return ['Name', 'SatNo', 'CountryId', 'ObjectType', 'Regime', 'HrrRank'].some(field => {
            const fieldValue = satellite[field]?.toString().toLowerCase() || '';
            return fieldValue.includes(searchItem.trim().toLowerCase());
        });
    }));
    let results = [];
    matches.forEach(match => {
        results = [...results, ...match];
    });
    results = [...new Set(results)];
    results.sort((a, b) => a.SatNo - b.SatNo);
    setFilteredSats(results);
}, 500);

export const filterSatellites = (satellites, filters, hrrChecked) => {
    return Object.values(satellites).filter(satellite => {
        if (hrrChecked && satellite.HrrRank === null) {
            return false;
        }
        return filters.every(({ parameter, operator, value }) => {
            let satelliteValue = satellite[parameter];
            if (parameter === 'HrrRank' && satelliteValue !== null) {
                satelliteValue = parseFloat(satelliteValue);
                value = parseFloat(value);
            }
            switch (operator) {
                case '=': return satelliteValue === value;
                case '!=': return satelliteValue !== value;
                case '<': return satelliteValue < value;
                case '>': return satelliteValue > value;
                case '<=': return satelliteValue <= value;
                case '>=': return satelliteValue >= value;
                default: return true;
            }
        });
    });
};