import React from 'react';
import sat_not_found from "../assets/images/sat_not_found.png";
import {Image} from 'react-bootstrap';
const NotFound = ({
    id,
}) => {
  return (
    <>
        <div 
            className="text-center mt-2 mb-5"
            id="summary"
        >
            <h2 style={{ marginBottom: '20px' }}>Dossier not found.</h2>
            <p style={{ marginBottom: '20px' }}>SatNo: {id}</p>
            <Image
                src={sat_not_found}
            />
        </div>
    </>
  )
};

export default NotFound;
