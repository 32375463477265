import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import About from './pages/About';
import Dossier from './pages/Dossier';
import DossierMin from './pages/DossierMin';
import Home from './pages/Home';
import ModalLogin from './components/ModalLogin';
import { Connect, MsgTypes } from "./utils/Websockets";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from "./contexts/user";
import Cookies from 'js-cookie';


const MainContent = ({
    setShowLoginModal,
    username,
}) => {
    const location = useLocation();
    const hideHeaderFooter = /^\/dossier-min\/\d+/.test(location.pathname);

    return (
        <>
            <ToastContainer />
            {!hideHeaderFooter && <Header  
                setShowLoginModal={setShowLoginModal}
                username={username}
            />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/dossier/:id" element={<Dossier />} />
                <Route path="/dossier-min/:id" element={<DossierMin />} />
            </Routes>
            {!hideHeaderFooter && <Footer />}
        </>
    );
};

const Main = () => {
    const { setWs, setNewMsg, ws, newMsg, username, setUsername } = useUser();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setWs(Connect(setNewMsg));
    }, [setNewMsg, setWs]);

    // Handle login response globally
    useEffect(() => {
        if (newMsg && newMsg.id === MsgTypes.Login) {
            setDisabled(false);
            // User logged in successfully
            if (newMsg.data.NPSLogin && newMsg.data.UDLLogin) {
                ws.getAllPsraData();
                Cookies.set('accessToken', newMsg.data.AccessToken, { secure: true, expires: 1, sameSite: 'Strict' });
                setUsername(newMsg.data.Username);
                setShowLoginModal(false);
            }
            // Guest login
            else if (newMsg.data.NPSLogin) {
                Cookies.remove("accessToken");
                ws.getAllPsraData();
                setUsername("Guest");
            } else {
                toast.error("Invalid username or password");
            }
        }
    }, [newMsg, setUsername, ws]);

    // Use useCallback to stabilize the function reference
    const handleLogin = useCallback((username, password) => {
        setDisabled(true);
        ws.login(username, password);
    }, [ws]);
    
    return (
        <Router>
            <MainContent
                setShowLoginModal={setShowLoginModal}
                username={username}
            />
            <ModalLogin 
                show={showLoginModal} 
                setShow={setShowLoginModal} 
                handleLogin={handleLogin}  // Ensure this is being passed correctly
                disabled={disabled} 
            />
        </Router>
    );
};

export default Main;