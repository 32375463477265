import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import '../css/login.css';

const Login = ({
    show,
    setShow,
    handleLogin, 
    disabled
}) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin(username, password); 
    };

    return (
        <>
            <Modal
                className="login-modal"
                show={show}
                onHide={() => setShow(false)}
            >
                <Modal.Body>
                    <p>Login using your UDL Credentials</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Control
                                style={{ marginBottom: "20px" }}
                                type="text"
                                placeholder="Username"
                                name="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <Form.Control
                                style={{ marginBottom: "20px" }}
                                autoComplete="new-password"
                                type="password"
                                name="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                            />
                            <Button
                                disabled={disabled}
                                variant="light"
                                type="submit"
                            >
                                Login
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Login;
